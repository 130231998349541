import React, { useEffect, useState, useMemo, useCallback } from "react";
import { notebookData } from "../data/notebookData";
import { useNavigate } from "react-router-dom";

const PlayNotesQuiz = () => {
    const navigate = useNavigate();

    const [selectedLanguage, setSelectedLanguage] = useState("python");
    const [randomQuestion, setRandomQuestion] = useState(null);

    useEffect(() => {
        getRandomQuestion();
    }, [selectedLanguage]);

    const getRandomQuestion = useCallback(() => {
        const questions = notebookData?.[selectedLanguage]?.pages || [];
        if (questions.length === 0) return;
        setRandomQuestion(questions[Math.floor(Math.random() * questions.length)]);
    }, [selectedLanguage]);

    const formatText = useMemo(() => (text) => {
        return text.split("\n").map((line, index) => {
            if (/^[-*]\s/.test(line)) return <li key={index} style={styles.listItem}>{formatInlineText(line.slice(2))}</li>;
            if (/^\d+\.\s/.test(line)) return <li key={index} style={styles.listItem}>{formatInlineText(line.replace(/^\d+\.\s/, ""))}</li>;
            return <p key={index} style={styles.paragraph}>{formatInlineText(line)}</p>;
        });
    }, []);

    const formatInlineText = (text) => {
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: text
                        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                        .replace(/\*(.*?)\*/g, "<i>$1</i>")
                        .replace(/`(.*?)`/g, `<span style="${styles.inlineCode}">$1</span>`),
                }}
            />
        );
    };

    const highlightCode = useMemo(() => (code) => {
            return code
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/(["'`])(?:\\.|[^\\])*?\1/g, `<span style="color: #CE9178;">$&</span>`) // Strings
                .replace(/\b(const|let|var|if|else|for|while|function|return|class|import|export|new|this|switch|case|break|script)\b/g, `<span style="color: #569CD6; font-weight: bold;">$&</span>`) // Keywords
                .replace(/\b(true|false|null|undefined|NaN)\b/g, `<span style="color: #D19A66;">$&</span>`) // Boolean
                .replace(/\b(\d+)\b/g, `<span style="color: #B5CEA8;">$&</span>`) // Numbers
                .replace(/(\/\/.*)/g, '<span style="color:rgb(81, 120, 73);">$1</span>')
    }, []);

    const renderContent = (content) => {
        return content?.map((item, index) => {
            switch (item.type) {
                case "text":
                    return <div key={index}>{formatText(item.value)}</div>;
                case "code":
                    return (
                        <pre key={index} style={styles.codeBlock}>
                            <code dangerouslySetInnerHTML={{ __html: highlightCode(item.value) }} />
                        </pre>
                    );
                case "image":
                    return <img key={index} src={item.value} alt="content" style={styles.image} />;
                default:
                    return null;
            }
        });
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <button style={styles.backButton} onClick={()=>{navigate("/notebook")}}>⬅ Back</button>
                <h1 style={styles.title}>Play Quiz</h1>
                <select style={styles.select} onChange={(e) => setSelectedLanguage(e.target.value)}>
                    {["python", "javascript", "nodejs", "reactjs", "typescript", "database", "hr"].map((lang) => (
                        <option key={lang} value={lang}>{lang.charAt(0).toUpperCase() + lang.slice(1)}</option>
                    ))}
                </select>
            </div>

            {randomQuestion && (
                <div style={styles.quizCard}>
                    <div style={styles.quizHeader}>
                        <h2 style={styles.quizTitle}>{randomQuestion.description}</h2>
                        <button style={styles.nextButton} onClick={getRandomQuestion}>Next Question →</button>
                    </div>
                    {renderContent(randomQuestion.content)}
                </div>
            )}
        </div>
    );
};

// 🎨 **Futuristic Glassmorphism & Neon Effects**
const styles = {
    backButton: {
        position: "absolute",
        top: "100px",
        left: "20px",
        padding: "10px 15px",
        fontSize: "16px",
        fontWeight: "bold",
        background: "rgba(255, 255, 255, 0.1)",
        color: "#fff",
        border: "2px solid transparent",
        borderRadius: "8px",
        cursor: "pointer",
        backdropFilter: "blur(10px)",
        transition: "all 0.3s ease",
      },
      backButtonHover: {
        background: "rgba(255, 255, 255, 0.2)",
        borderColor: "#00e676",
      },
    container: {
        background: "#181818",
        minHeight: "100%",
        padding: "20px",
        fontFamily: "'Poppins', sans-serif",
        color: "#fff",
        textAlign: "left",
    },
    header: { textAlign: "center", marginTop: "60px" },
    title: {
        fontSize: "3rem",
        fontWeight: "bold",
        textShadow: "0 0 10px rgba(255, 255, 255, 0.6)",
        background: "linear-gradient(135deg, #ff79c6, #bd93f9)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
    },
    select: {
        borderRadius: "8px",
        backgroundColor: "#21262d",
        color: "#c9d1d9",
        padding: "12px",
        border: "1px solid rgba(255, 255, 255, 0.2)",
        fontSize: "1rem",
        cursor: "pointer",
        // marginTop: "20px",
        transition: "all 0.3s ease",
    },
    quizCard: {
        maxWidth: "800px",
        margin: "40px auto",
        background: "rgba(33, 38, 45, 0.8)",
        padding: "25px",
        borderRadius: "15px",
        boxShadow: "0 0 20px rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(10px)",
        border: "1px solid rgba(255, 255, 255, 0.2)",
    },
    quizHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
    },
    quizTitle: { fontSize: "1.8rem", color: "#c9d1d9" },
    nextButton: {
        background: "linear-gradient(135deg, #ff79c6, #bd93f9)",
        borderRadius: "8px",
        padding: "12px 20px",
        color: "#fff",
        cursor: "pointer",
        border: "none",
        fontSize: "1rem",
        transition: "all 0.3s ease",
        boxShadow: "0 0 10px rgba(255, 121, 198, 0.6)",
    },
    codeBlock: {
        backgroundColor: "#161b22",
        color: "#c9d1d9",
        padding: "15px",
        borderRadius: "8px",
        overflowX: "auto",
        fontSize: "14px",
        fontFamily: "'Fira Code', monospace",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
    },
    inlineCode: "color: #ff79c6; background: rgba(255, 121, 198, 0.1); padding: 2px 5px; border-radius: 4px; font-family: 'Fira Code', monospace;",
    listItem: { marginBottom: "8px", listStyleType: "disc", marginLeft: "20px" },
    paragraph: { marginBottom: "10px" },
};

export default PlayNotesQuiz;
