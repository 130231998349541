import React, { useEffect, useRef, useMemo } from "react";
import { notebookData } from "../data/notebookData";

const Notebook = ({ selectedLanguage, selectedPage }) => {
    const pages = notebookData[selectedLanguage]?.pages || [];
    const pageRefs = useRef([]); // Store refs for each page

    useEffect(() => {
        if (pageRefs.current[selectedPage]) {
            pageRefs.current[selectedPage].scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [selectedPage]); // Runs when selectedPage updates

    const formatText = useMemo(() => (text) => {
        return text.split("\n").map((line, index) => {
            if (/^[-*]\s/.test(line)) return <li key={index} style={styles.listItem}>{formatInlineText(line.slice(2))}</li>;
            if (/^\d+\.\s/.test(line)) return <li key={index} style={styles.listItem}>{formatInlineText(line.replace(/^\d+\.\s/, ""))}</li>;
            return <p key={index} style={styles.paragraph}>{formatInlineText(line)}</p>;
        });
    }, []);

    const formatInlineText = (text) => {
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: text
                        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                        .replace(/\*(.*?)\*/g, "<i>$1</i>")
                        .replace(/`(.*?)`/g, `<span style="${styles.inlineCode}">$1</span>`),
                }}
            />
        );
    };

    const renderContent = (content) => {
        return content.map((item, index) => {
            switch (item.type) {
                case "text":
                    return <div key={index}>{formatText(item.value)}</div>;
                case "code":
                    return (
                        <pre key={index} style={styles.pre}>
                            <code
                                style={styles.code}
                                dangerouslySetInnerHTML={{ __html: highlightCode(item.value) }}
                            />
                        </pre>
                    );
                case "image":
                    return <img key={index} src={item.value} alt="content" style={styles.image} />;
                default:
                    return null;
            }
        });
    };

    const highlightCode = (code) => {
        return code
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/(["'`])(?:\\.|[^\\])*?\1/g, `<span style="color: #CE9178;">$&</span>`) // Strings
            .replace(/\b(const|let|var|if|else|for|while|function|return|class|import|export|new|this|switch|case|break|script)\b/g, `<span style="color: #569CD6; font-weight: bold;">$&</span>`) // Keywords
            .replace(/\b(true|false|null|undefined|NaN)\b/g, `<span style="color: #D19A66;">$&</span>`) // Boolean
            .replace(/\b(\d+)\b/g, `<span style="color: #B5CEA8;">$&</span>`) // Numbers
            .replace(/(\/\/.*)/g, '<span style="color:rgb(81, 120, 73);">$1</span>')
    };

    return (
        <div style={styles.container}>
            <div style={styles.quizLink}>
                <a href="/playquize">Play Quiz</a>
            </div>
            <div style={styles.scrollContainer}>
                {pages.map((page, index) => (
                    <div
                        key={index}
                        ref={(el) => (pageRefs.current[index] = el)} // Assign ref to each page
                        style={selectedPage === index ? { ...styles.note, ...styles.highlightnote } : styles.note}
                    >
                        <h2 style={selectedPage === index ? { ...styles.h2, color: "#FFD700" } : styles.h2}>
                            {index+1}. {page.description}
                        </h2>
                        {renderContent(page.content)}
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    container: {
        flex: 1,
        padding: "40px 20px 20px",
        backgroundColor: "#181818",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        maxWidth: "850px",
        marginInline: "auto",
        color: "#E0E0E0",
        height: "100%",
        overflow: "hidden",
    },
    scrollContainer: {
        height: "100%",
        overflowY: "auto", // Enables scrolling
        paddingRight: "10px",
    },
    quizLink: {
        position: "absolute",
        top: "100px",
        right: "30px",
        padding: "4px 8px",
        fontSize: "16px",
        fontWeight: "bold",
        background: "rgba(255, 255, 255, 0.1)",
        color: "#fff",
        border: "2px solid transparent",
        borderRadius: "8px",
        cursor: "pointer",
        backdropFilter: "blur(10px)",
        transition: "all 0.3s ease",
        zIndex: 1000,

    },
    note: {
        marginBottom: "20px",
        padding: "15px",
        borderRadius: "8px",
        backgroundColor: "#222",
        transition: "all 0.3s ease",
    },
    highlightnote: {
        // background: "linear-gradient(135deg, #333, #444)",
        boxShadow: "0px 0px 12px rgba(255, 215, 0, 0.7)",
        border: "2px solid #FFD700",
        transform: "scale(1.02)",
    },
    pre: {
        backgroundColor: "#1e1e1e",
        padding: "10px",
        borderRadius: "5px",
        overflowX: "auto",
        marginBottom: "10px",

    },
    code: {
        fontFamily: "monospace",
        color: "#80FFEA",
    },
    image: {
        maxWidth: "100%",
        height: "auto",
        margin: "10px 0",
        borderRadius: "5px",
    },
    h2: {
        color: "#00E676",
        borderBottom: "2px solid #333",
        paddingBottom: "5px",
        marginBottom: "10px",
        fontWeight: "bold",
    },
    p: {
        lineHeight: 1.6,
        marginBottom: "10px",
    },
};

export default Notebook;
