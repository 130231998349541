import React, { useState } from 'react';
import NoteSidebar from '../components/NoteSidebar';
import Notebook from '../components/Notebook';

const NoteBookPage = () => {
    const [selectedLanguage, setSelectedLanguage] = useState("python");
    const [selectedPage, setSelectedPage] = useState(null);

    return (
      <div style={styles.page}>
        <NoteSidebar
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          setSelectedPage={setSelectedPage}
        />
        <Notebook selectedLanguage={selectedLanguage} selectedPage={selectedPage} />
      </div>
    );
};

const styles = {
  page: {
    display: "flex",
    height: "100%",
    backgroundColor: "#121212",
    textAlign: "left",
    paddingTop: "80px",
    overflow: "hidden",
    paddingBottom:20
  },
};

export default NoteBookPage;