import React, { useState } from "react";
import { notebookData } from "../data/notebookData";

const NoteSidebar = ({ selectedLanguage, setSelectedLanguage, selectedPage, setSelectedPage }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Toggle state for sidebar

  return (
    <div style={{ display: "flex" }}>
      {/* Toggle Button (Hamburger) */}
      <button style={styles.toggleButton} onClick={() => setSidebarOpen(!sidebarOpen)}>
        {sidebarOpen ? "✖" : "☰"}
      </button>

      {/* Sidebar */}
      <div style={{ ...styles.sidebar, transform: sidebarOpen ? "translateX(0)" : "translateX(-100%)" }}>
        {/* Language Selection - Dropdown */}
        <div style={styles.dropdownContainer}>
          <button style={styles.dropdownButton} onClick={() => setDropdownOpen(!dropdownOpen)}>
            {selectedLanguage.charAt(0).toUpperCase() + selectedLanguage.slice(1)} ▼
          </button>
          {dropdownOpen && (
            <div style={styles.dropdownMenu}>
              {["python", "javascript", "nodejs", "typescript", "database", "hr", "reactjs"].map((lang) => (
                <div
                  key={lang}
                  style={selectedLanguage === lang ? { ...styles.dropdownItem, ...styles.activeDropdownItem } : styles.dropdownItem}
                  onClick={() => {
                    setSelectedLanguage(lang);
                    setDropdownOpen(false);
                  }}
                >
                  {lang.charAt(0).toUpperCase() + lang.slice(1)}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Notes List */}
        <h2 style={styles.heading}>Notes</h2>
        <div style={styles.notesContainer}>
          {notebookData[selectedLanguage]?.pages.map((page, index) => (
            <div
              key={index}
              style={selectedPage === index ? { ...styles.note, ...styles.activeNote } : styles.note}
              onClick={() => setSelectedPage(index)}
            >
              <h3 style={styles.noteTitle}>{page.description}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  toggleButton: {
    position: "absolute",
    top: "100px",
    left: "20px",
    padding: "10px 15px",
    fontSize: "18px",
    fontWeight: "bold",
    background: "rgba(255, 255, 255, 0.1)",
    color: "#fff",
    border: "2px solid transparent",
    borderRadius: "8px",
    cursor: "pointer",
    backdropFilter: "blur(10px)",
    transition: "all 0.3s ease",
    zIndex: 1000, // Ensures the button stays on top
  },
  sidebar: {
    position: "fixed",
    top: 80,
    left: 0,
    width: "300px",
    height: "calc(100% - 80px)",
    background: "rgba(30, 30, 30, 0.8)",
    padding: "20px",
    borderRight: "2px solid rgba(255, 255, 255, 0.1)",
    overflowY: "auto",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    backdropFilter: "blur(10px)",
    boxShadow: "5px 0 15px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.3s ease-in-out",
    zIndex: 99
  },
  dropdownContainer: {
    position: "relative",
    marginBottom: "20px",
  },
  dropdownButton: {
    width: "100%",
    padding: "12px",
    fontSize: "16px",
    fontWeight: "bold",
    background: "rgba(255, 255, 255, 0.1)",
    color: "#fff",
    border: "2px solid transparent",
    borderRadius: "6px",
    cursor: "pointer",
    textAlign: "center",
    transition: "all 0.3s",
  },
  dropdownMenu: {
    position: "absolute",
    top: "100%",
    left: 0,
    width: "100%",
    background: "rgba(40, 40, 40, 0.9)",
    borderRadius: "6px",
    overflow: "hidden",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
  },
  dropdownItem: {
    padding: "12px",
    fontSize: "15px",
    color: "#ccc",
    cursor: "pointer",
    transition: "background 0.3s, color 0.3s",
  },
  activeDropdownItem: {
    background: "rgba(0, 230, 118, 0.3)",
    color: "#00e676",
    fontWeight: "bold",
  },
  heading: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "12px",
    textTransform: "uppercase",
    color: "#00e676",
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
    paddingBottom: "5px",
  },
  notesContainer: {
    flex: 1,
    overflowY: "auto",
    paddingRight: "8px",
    scrollbarWidth: "thin",
    scrollbarColor: "#444 #222",
  },
  note: {
    padding: "12px",
    marginBottom: "10px",
    borderRadius: "6px",
    background: "rgba(255, 255, 255, 0.05)",
    cursor: "pointer",
    transition: "all 0.3s ease",
  },
  activeNote: {
    background: "rgba(255, 215, 0, 0.2)",
    boxShadow: "0px 0px 10px rgba(255, 215, 0, 0.7)",
    border: "2px solid #FFD700",
    transform: "scale(1.05)",
  },
  noteTitle: {
    color: "#fff",
    fontSize: "15px",
    fontWeight: "500",
    transition: "color 0.3s",
  },
};

export default NoteSidebar;
