import React from 'react'
import './Resume.css'
const Resume = () => {
    const handlePress = () => {
        window.print({ title: "Amit's_resume.pdf" });
    }
    return (
        <div className='resume_body'>
            <div className="resume print-content" media="print">
                <header className='resume_header'>
                    <div className='resume_header--left'>
                        <h1 className='resume_header--heading'>AMIT KUMAR</h1>
                        <p>Experienced Full Stack Developer with 3+ years of expertise in React.js, Node.js, and React Native. Skilled in building scalable web and mobile applications, integrating AI-driven features, and optimizing databases for performance. Proven ability to lead end-to-end development projects, ensuring user satisfaction and client success.</p>
                    </div>
                    <div class="resume_header--contact-info">
                        <span><a href="https://www.devamit.in" target="_blank">devamit.in</a> <span style={{ fontSize: 18 }}>🌐</span></span>
                        <span><a href="mailto:amit115017@gmail.com">amit115017@gmail.com</a> <span style={{ fontSize: 18 }}>📧</span></span>
                        <span><a href='tel:+91 9852972838' target="_blank" >+91 9852972838</a> <span style={{ fontSize: 18 }}>📞</span></span>
                        <span>New Delhi, India <span style={{ fontSize: 18 }}>📍</span></span>
                        <span><a href='https://www.linkedin.com/in/amit2197' target="_blank">linkedin.com/in/amit2197</a> <span className='lnIcon'>in</span></span>
                        <span><a href='https://github.com/amit2197' target="_blank" >github.com/amit2197</a> <span style={{ fontSize: 12, backgroundColor: "#333", fontFamily: "Arial, sans-serif", padding: 2, color: "#fafafa", fontWeight: "bold", height: 16, width: 18, alignItems: "center", justifyContent: "center", display: "flex", borderRadius: 3, lineHeight: 1 }}>git</span></span>
                    </div>
                </header>
                <section class="resume_skills">
                    <h2>Technical Skills</h2>
                    <ul>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Languages:</strong> Python 3, JavaScript, TypeScript, HTML5, CSS3</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Frameworks:</strong> Node.js, React.js, Express.js, React Native, Flask, NestJS, NextJs, Socket.IO</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Databases:</strong> MySQL, MongoDB, DynamoDB, PostgreSQL, BigQuery</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Cloud & Tools:</strong> AWS, Kafka, GitHub, Visual Studio Code</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Soft Skills:</strong> Problem-Solving, Time Management, Collaboration </li>
                    </ul>
                </section>
                <section class="resume_experience">
                    <h2>WORK EXPERIENCE</h2>
                    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
                        <div class="resume_experience--item">
                            <div>
                                <div>
                                    <h3>Software Engineer</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://phnk.in" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@Phnk Networks India Pvt. Ltd</a></p>
                                </div>
                                <p><strong>July 2022 – Present</strong></p>
                            </div>
                            <ul>
                                <li><b>Database Optimization: </b>Redesigned MySQL and DynamoDB schemas, improving query speeds by 35% and reducing data inconsistencies</li>
                                <li><b>Real-Time Communication: </b>Built Kafka-integrated Node.js APIs for chat functionality, achieving 99.9% uptime across all users.</li>
                                <li><b>Payment Integration: </b>Streamlined Razorpay and Google Play payment gateways, achieving a 95% transaction success rate.</li>
                                <li><b>Full-Stack Leadership: </b>Led end-to-end development of 3 core product modules, reducing deployment cycles by 40% through Agile practices.</li>
                                <li><b>AI-Driven Features: </b>Executed AI-driven features, such as avatar generation and real-time messaging, to enhance user engagement.</li>
                            </ul>
                        </div>
                        <div class="resume_experience--item">
                            <div>
                                <div>
                                    <h3>Software Engineer</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://tweencraft.com/" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@Kalpkriti Pvt. Ltd</a></p>
                                </div>
                                <p><strong>September 2021 – July 2022</strong></p>
                            </div>
                            <ul>
                                <li><b>Frontend Optimization: </b>Redesigned Twencraft’s React.js-based website, reducing page load time by 40%</li>
                                <li><b>API Scalability: </b>Optimized 15+ RESTful APIs (Node.js/Express.js/MongoDB) to handle 5,000+ requests/minute.</li>
                                <li><b>Automation Efficiency: </b>Engineered Python scripts to automate data entry workflows, eliminating 90% of errors.</li>
                                <li><b>Data-Driven Growth: </b>Leveraged BigQuery to analyze 1M+ user interactions, enabling a 15% increase in conversion rates.</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section class="resume_projects">
                    <h2>PROJECTS</h2>
                    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
                        <div class="resume_project--item">
                        
                            <div>
                                <div>
                                    <h3>E-Commerce Platform (Next.js + NestJS)</h3>
                                    {/* <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://devamit.in" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@devamit.in</a></p> */}
                                </div>
                                <p><strong>2025 <span style={{fontStyle:"italic", fontSize: 10}}>(In Progress)</span></strong></p>
                            </div>
                            <ul>
                                <li>Developing REST API with NestJS/TypeScript and frontend with Next.js for SEO optimization.</li>
                                <li>Implementing JWT authentication and TypeORM for MySQL integration.</li>
                            </ul>
                        </div>
                        <div className="page-break"></div>
                <div class="page-breake-space"></div>
                        <div class="resume_project--item" break={true}>
                            <div>
                                <div>
                                    <h3>Portfolio Website</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://devamit.in" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@devamit.in</a></p>
                                </div>
                                <p><strong>2024</strong></p>
                            </div>
                            <ul>
                                <li>Built with HTML5, CSS3, and JavaScript to showcase skills and projects.</li>
                                <li>Achieved 88+ Lighthouse performance score through optimized code and asset compression.</li>
                            </ul>
                        </div>
                        {/* <div class="resume_project--item" >
                            <div>
                                <div>
                                    <h3>Phnk: Comprehensive App, Website, and Server Development </h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://phnk.in" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@Phnk Networks India Pvt. Ltd</a></p>
                                </div>
                                <p><strong>July 22- Present</strong></p>
                            </div>
                            <ul>
                                <li>Integrated Kafka-based chat for real-time user communication.</li>
                                <li>Implemented payment gateways (Razorpay, Google Play) for in-app purchases.</li>
                                <li>Developed AI avatar generation and chat features to enhance user interaction.</li>
                                <li>Ensured platform scalability and performance through robust architecture.</li>
                            </ul>
                        </div> */}
                        
                        
                        <div class="resume_project--item">
                            <div>
                                <div>
                                    <h3>AMRITPEX 2023</h3>
                                </div>
                                <p><strong>January 23- March 23</strong></p>
                            </div>
                            <ul>
                                <li>Led development of an Android app with user authentication, activity feed, gallery, and QR code generation.</li>
                                <li>Designed a secure backend using Node.js and a scalable database schema.</li>
                            </ul>
                        </div>
                        {/* <div class="resume_project--item">
                            <div>
                                <div>
                                    <h3>Tweencraft: Comprehensive Website and Server Development</h3>
                                    <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://tweencraft.com/" style={{ fontSize: 13, color: "#305072" }} target='_blank'>@Kalpkriti Pvt. Ltd</a></p>
                                </div>
                                <p><strong>September 21- July 22</strong></p>
                            </div>
                            <ul>
                                <li>Redesigned the website for improved UX and visual appeal.</li>
                                <li>Built RESTful APIs using Node.js, Express.js, and MongoDB.</li>
                                <li>Created automation scripts in Python to streamline operations and resolve issues efficiently.</li>
                                <li>Utilized BigQuery for data analysis, enabling informed decision-making through insightful reports and graphs.</li>
                            </ul>
                        </div> */}
                    </div>
                </section>
                {/* <section className="resume_open_source">
                    <h2>OPEN SOURCE PACKAGES</h2>
                    <div className="resume_project--item">
                        <div>
                            <h3>smart-env-switcher</h3>
                            <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://www.npmjs.com/package/smart-env-switcher" style={{ fontSize: 13, color: "#305072" }} target='_blank'>npm</a></p>
                        </div>
                        <ul>
                            <li>Node.js library for managing environment variables.</li>
                        </ul>
                    </div>
                    <div className="resume_project--item">
                        <div>
                            <h3>markdown-htmlify-react</h3>
                            <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://www.npmjs.com/package/markdown-htmlify" style={{ fontSize: 13, color: "#305072" }} target='_blank'>npm</a></p>
                        </div>
                        <ul>
                            <li>React component for converting Markdown to HTML.</li>
                        </ul>
                    </div>
                    <div className="resume_project--item">
                        <div>
                            <h3>react-enhanced-text-editor</h3>
                            <p style={{ fontSize: 13, color: "#333333" }}> <a href="https://www.npmjs.com/package/markdown-htmlify" style={{ fontSize: 13, color: "#305072" }} target='_blank'>npm</a></p>
                        </div>
                        <ul>
                            <li>A simple and customizable rich text editor built with React.</li>
                        </ul>
                    </div>
                </section> */}
                <section class="resume_education">
                    <h2>EDUCATION</h2>
                    <div class="resume_education--item">
                        <div>
                            <h3>Dr. B.C. Roy Engineering College <a href="https://bcrec.ac.in/" target='_blank'></a></h3>
                            <p>B.Tech in Computer Science</p>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <p>Durgapur, India</p>
                            <p><strong>July 2015 - August 2019</strong></p>
                        </div>
                    </div>
                </section>
                <section class="resume_certificates">
                    <h2>CERTIFICATES</h2>
                    <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
                        <div class="resume_certificate--item">
                            <div>
                                <div>
                                    <h3>Full Stack Web Developer Nanodegree (<a href="https://udacity.com" style={{ fontSize: 13, color: "#333333" }} target='_blank'>@Udacity</a>)</h3>

                                </div>
                                <p><strong>July 2022 – January 2023</strong></p>
                            </div>
                            <ul>
                                <li>Deployed Flask apps on AWS </li>
                                <li style={{ textDecoration: "none", gap: 10 }}>Certificates:
                                    <a href="https://bit.ly/3UW4PwI" style={{ marginLeft: 5, marginRight: 10 }} target="_blank" rel="noopener noreferrer">bit.ly/3UW4PwI,</a>
                                    <a href="https://bit.ly/3AI2779" target="_blank" rel="noopener noreferrer">bit.ly/3AI2779</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section class="resume_coding_profiles">
                    <h2>Coding Profiles</h2>
                    <ul>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>HackerRank:</strong> <a href='https://www.hackerrank.com/amit2197' target='_blank'>https://www.hackerrank.com/amit2197</a> (100+ problems solved)</li>
                        <li><strong style={{ minWidth: 100, display: 'inline-block' }}>Codewars:</strong> <a href='https://www.codewars.com/users/amit2197' target='_blank'>https://www.codewars.com/users/amit2197</a> (200+ problems solved)</li>
                    </ul>
                </section>
            </div>
            <button className='resume-download' onClick={() => {
                document.title = '';
                handlePress()
            }}>Print</button>
        </div>
    )
}
export default Resume;