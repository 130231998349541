import React from 'react';
import './PackagesList.css';
// import projectImage from '../assets/profile.jpg';
import { ReactComponent as GithubLogo } from '../assets/github.svg';
import { ReactComponent as NpmLogo } from '../assets/npm.svg';
import { ReactComponent as CodeSandbox } from '../assets/codesandbox.svg';
import { ReactComponent as WebsiteLogo } from '../assets/website.svg';

const OpenSourcePackages = [
    {
        name: 'smart-env-switcher',
        description: 'SmartEnvSwitcher is a lightweight Node.js library for managing environment variables across multiple environments, such as development, staging, and production. It simplifies the process of switching between environments and updating `.env` files programm',
        type: "npm",
        code: 'npm i smart-env-switcher',
        logo: NpmLogo,
        keywords: ["nodejs",
            "environment",
            "switcher",
            "env",
            "typescript"],
        availableOn: [
            { name: 'npm', link: 'https://www.npmjs.com/package/smart-env-switcher', title: "View on npm" },
            { name: 'github', link: 'https://github.com/Amit2197/smart-env-switcher', title: "View source code" },
            { name: 'codesandbox', link: 'https://codesandbox.io/embed/smart-env-switcher-test-yx5lfp', title: "View demo" },
            { name: 'devamit.in', link: '/packages/smart-env-switcher', title: "View documentation" },]
    },
    {
        name: "markdown-htmlify",
        description:
            "A versatile Node.js package for converting Markdown to HTML. Fetch Markdown content from a URL, local file, or text string, and render it into HTML.",
        type: "npm",
        code: "npm i markdown-htmlify",
        logo: NpmLogo,
        keywords: [
            "markdown",
            "renderer",
            "html",
            "nodejs",
            "typescript",
            "html",
        ],
        availableOn: [
            {
                name: "npm",
                link: "https://www.npmjs.com/package/markdown-htmlify",
                title: "View on npm",
            },
            {
                name: "github",
                link: "https://github.com/Amit2197/markdown-htmlify",
                title: "View source code",
            },
            {
                name: "codesandbox",
                link: "https://codesandbox.io/embed/markdown-htmlify-test-yx5lfp",
                title: "View demo",
            },
            {
                name: "devamit.in",
                link: "/packages/markdown-htmlify",
                title: "View documentation",
            },
        ],
    },
    {
        name: "markdown-htmlify-react",
        description:
            "A React component to convert Markdown to HTML, supporting input from URL, local file, or string.",
        type: "npm",
        code: "npm i markdown-htmlify-react",
        logo: NpmLogo,
        keywords: [
            "react",
            "markdown",
            "html",
            "converter",
            "markdown-to-html",
            "react-component"
        ],
        availableOn: [
            {
                name: "npm",
                link: "https://www.npmjs.com/package/markdown-htmlify-react",
                title: "View on npm",
            },
            {
                name: "github",
                link: "https://github.com/Amit2197/markdown-htmlify-react",
                title: "View source code",
            },
            {
                name: "codesandbox",
                link: "https://codesandbox.io/embed/markdown-htmlify-react-test-yx5lfp",
                title: "View demo",
            },
            {
                name: "devamit.in",
                link: "/packages/markdown-htmlify-react",
                title: "View documentation",
            },
        ],
    },
    {
        name: "react-enhanced-text-editor",
        description:
            "A simple and customizable rich text editor built with React. This editor supports various text formatting options and media embedding, making it suitable for a wide range of applications.",
        type: "npm",
        code: "npm i react-enhanced-text-editor",
        logo: NpmLogo,
        keywords: [
            "react", "rich-text-editor", "editor", "text-format", "tingwysiwyg", "text-editor"
        ],
        availableOn: [
            {
                name: "npm",
                link: "https://www.npmjs.com/package/react-enhanced-text-editor",
                title: "View on npm",
            },
            {
                name: "github",
                link: "https://github.com/Amit2197/react-enhanced-text-editor",
                title: "View source code",
            },
            {
                name: "codesandbox",
                link: "https://codesandbox.io/embed/react-enhanced-text-editor-test-yx5lfp",
                title: "View demo",
            },
            {
                name: "devamit.in",
                link: "/packages/react-enhanced-text-editor",
                title: "View documentation",
            },
        ],
    },
];

const PackagesList = () => {
    const [onHoverKeywords, setOnHoverKeywords] = React.useState(null);

    return (
        <section className="section_npm-packages">
            <h2>Open Source Packages</h2>
            <div className="package-list">
                {OpenSourcePackages.map((Pkg, index) => (
                    <div key={index} className="package-item">
                        {/* Logo */}
                        {/* <div className="package-logo">
                      <Pkg.logo className="logo" />
                    </div> */}

                        {/* Package Details */}
                        <div className="package-content">
                            <a href={Pkg.link} target="_blank" rel="noopener noreferrer">
                                <h3>{Pkg.name}</h3>
                                <p>{Pkg.description}</p>
                            </a>
                            <pre>
                                <code>{Pkg.code}</code>
                            </pre>

                            {/* Keywords */}
                            {/* <div className="keywords">
                        {Pkg.keywords.map((keyword, keyIndex) => (
                          <span key={keyIndex}>{keyword}</span>
                        ))}
                      </div> */}
                        </div>

                        {/* Available On */}
                        <div className="package-links">
                            {Pkg.availableOn.map((Available, avIndex) => (
                                <div
                                    key={avIndex}
                                    className="package-icon"
                                    onMouseOver={() => setOnHoverKeywords([index, avIndex])}
                                    onMouseOut={() => setOnHoverKeywords(null)}
                                >
                                    <a href={Available.link} target="_blank" rel="noopener noreferrer">
                                        {Available.name === "npm" ? (
                                            <NpmLogo className="icon" />
                                        ) : Available.name === "github" ? (
                                            <GithubLogo className="icon" />
                                        ) : Available.name === "devamit.in" ? (
                                            <WebsiteLogo className="icon" stroke="#fff" />
                                        ) : (
                                            <CodeSandbox className="icon" />
                                        )}
                                    </a>
                                    {onHoverKeywords?.[0] === index && onHoverKeywords?.[1] === avIndex && (
                                        <div className="tooltip">{Available.title}</div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default PackagesList;